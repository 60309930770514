import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAnalytics from '../../hooks/useAnalytics';
import { createNewConversation } from '../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { ApiResponse, CreateNewConversationResponse } from '../../services/apiService/definitions/types';
import { getLatestAnswerNodeOfQueryNode } from '../../utils/conversation';

import './QuestionTemplate.css';

interface QuestionTemplateProps {
  question: string;
  index: number;
}

const QuestionTemplate: React.FC<QuestionTemplateProps> = ({ question, index }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const projectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const isPro = useSelector((state: RootState) => state.user.isPro);
  const theme = useSelector((state: RootState) => state.user.theme);

  const onQuestionClick = async () => {
    try {
      analytics.trackChatSubmitEvent({
        mode: 'FAQ',
        is_cached: isCacheEnabled,
        is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
        conversation_id: conversationId || '',
        project_key: projectKey,
        is_pro: isPro,
      });
      const result = await dispatch(
        createNewConversation({
          project_key: projectKey,
          query: question.replace(/\n/g, ' '),
          is_cached: isCacheEnabled,
          is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
          is_pro: isPro,
        }),
      );
      if (result.type.endsWith('fulfilled')) {
        const {
          data: { conversation },
        } = result.payload as ApiResponse<CreateNewConversationResponse>;
        // For newly created conversation, tree would only have 2 nodes (query & answer)
        const newAnswerNode = getLatestAnswerNodeOfQueryNode(conversation.top_level_node_ids[0], conversation);
        if (newAnswerNode && newAnswerNode.data.engine_response) {
          analytics.trackChatResponseReceived({
            success: !newAnswerNode.data.engine_response.error_flag,
            chart_present: !!newAnswerNode.data.engine_response.chart_results,
            source: newAnswerNode.data.engine_response.answer_source,
            conversation_id: newAnswerNode.data.conversation_id,
            question_type:
              newAnswerNode.data.engine_response.response && newAnswerNode.data.engine_response.response.length > 1
                ? 'complex'
                : 'simple',
            chat_id: newAnswerNode.id,
            question_status: newAnswerNode.data.status,
            status: 'COMPLETED',
          });
        }
        navigate(`/chat/${conversation.conversation_id}`);
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  return (
    <div
      className="IC-question-template"
      onClick={() => onQuestionClick()}
      id={index === 0 ? 'component-b-element' : ''}
      style={{
        borderColor: theme === 'light-theme' ? 'rgba(0, 0, 0, 0.10)' : '',
      }}
    >
      <div className="IC-question-title">{question}</div>
      <div className="IC-question-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <mask id="mask0_2411_22393" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
            <rect y="0.882202" width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2411_22393)">
            <path
              d="M9.33329 12.8822L8.39996 11.9155L10.7666 9.54887H2.66663V8.21554H10.7666L8.39996 5.84887L9.33329 4.8822L13.3333 8.8822L9.33329 12.8822Z"
              fill="#B1B1B1"
            />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default QuestionTemplate;
