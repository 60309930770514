import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAnalytics from '../../hooks/useAnalytics';
import { addChatToConversation } from '../../redux/features/conversationSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { ApiResponse, CreateNewConversationResponse } from '../../services/apiService/definitions/types';
import { getLatestAnswerNodeOfQueryNode } from '../../utils/conversation';

import './SuggestedQuestions.css';

type Props = {
  answerNodeId: string;
};

const SuggestedQuestions: React.FC<Props> = ({ answerNodeId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const analytics = useAnalytics();
  const conversationTree = useSelector((state: RootState) => state.conversation.conversationTree);
  const isQuestionSubmitted = useSelector((state: RootState) => state.conversation.isQuestionSubmitted);
  const isCacheEnabled = useSelector((state: RootState) => state.conversation.isCacheEnabled);
  const conversationId = useSelector((state: RootState) => state.conversation.conversationId);
  const conversationChain = useSelector((state: RootState) => state.conversation.conversationChain);
  const isSemanticCacheEnabled = useSelector((state: RootState) => state.conversation.isSemanticCacheEnabled);
  const projectKey = useSelector((state: RootState) => state.sideBar.projectKey);
  const isPro = useSelector((state: RootState) => state.user.isPro);

  const answerNode = conversationTree?.nodes[answerNodeId];

  const onSuggestedQuestionClick = async (suggestedQuestion: string) => {
    try {
      if (conversationId) {
        analytics.trackChatSubmitEvent({
          mode: 'suggestion_click',
          is_cached: isCacheEnabled,
          is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
          conversation_id: conversationId || '',
          project_key: projectKey,
          is_pro: isPro,
        });
        const parentQueryNodeId = conversationChain[conversationChain.length - 1].queryNodeId;
        const result = await dispatch(
          addChatToConversation({
            conversationId,
            body: {
              parent_node_id: parentQueryNodeId,
              query: suggestedQuestion.replace(/\n/g, ' '),
              is_cached: isCacheEnabled,
              is_semantic_cache: isCacheEnabled ? isSemanticCacheEnabled : false,
              is_pro: isPro,
            },
          }),
        );
        if (result.type.endsWith('fulfilled')) {
          const {
            data: { conversation },
          } = result.payload as ApiResponse<CreateNewConversationResponse>;
          // For an existing tree, the new query node will be added as child of given `parentQueryNodeId`
          const newQueryNode = conversation.nodes[parentQueryNodeId];
          const newAnswerNode = getLatestAnswerNodeOfQueryNode(newQueryNode.id, conversation);
          if (newAnswerNode && newAnswerNode.data.engine_response) {
            analytics.trackChatResponseReceived({
              success: !newAnswerNode.data.engine_response.error_flag,
              chart_present: !!newAnswerNode.data.engine_response.chart_results,
              source: newAnswerNode.data.engine_response.answer_source,
              conversation_id: newAnswerNode.data.conversation_id,
              question_type:
                newAnswerNode.data.engine_response.response && newAnswerNode.data.engine_response.response.length > 1
                  ? 'complex'
                  : 'simple',
              chat_id: newAnswerNode.id,
              question_status: newAnswerNode.data.status,
              status: 'COMPLETED',
            });
          }
        }
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  const parseSuggestedQuestions = (): string[] => {
    if (!answerNode || answerNode.data.engine_response?.error_flag || isQuestionSubmitted) {
      return [];
    }
    const response = answerNode.data.engine_response?.suggested_questions;
    if (Array.isArray(response)) {
      return response;
    } else if (typeof response === 'string') {
      try {
        return response
          .slice(1, -1)
          .split("', '")
          .map((question) => question.replace(/^'(.*)'$/, '$1'));
      } catch (error) {
        console.error('Error parsing suggested questions:', error);
      }
    }
    return [];
  };

  const suggestedQuestions = parseSuggestedQuestions();

  return (
    <div className="suggested-questions">
      {suggestedQuestions.length > 0 ? (
        <div className="suggested-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_1540_2507)">
              <path
                className="svg-icon-path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.07002 0.883048C7.65582 0.594648 8.34435 0.594648 8.93015 0.883048L14.7648 3.75573C15.523 4.12899 15.523 5.20452 14.7648 5.57777L13.23 6.33341L14.7648 7.08902C15.523 7.46228 15.523 8.53782 14.7648 8.91108L13.23 9.66675L14.7648 10.4223C15.523 10.7956 15.523 11.8711 14.7648 12.2444L8.93015 15.1171C8.34435 15.4055 7.65582 15.4055 7.07002 15.1171L1.23534 12.2444C0.477215 11.8711 0.477219 10.7956 1.23534 10.4223L2.77013 9.66675L1.23534 8.91108C0.477215 8.53782 0.477219 7.46228 1.23534 7.08902L2.77012 6.33341L1.23534 5.57777C0.477215 5.20451 0.477219 4.12898 1.23534 3.75573L7.07002 0.883048ZM8.34115 2.07926C8.12675 1.97369 7.87342 1.97369 7.65902 2.07926L2.40353 4.66675L7.65902 7.25421C7.87342 7.35981 8.12675 7.35981 8.34115 7.25421L13.5966 4.66675L8.34115 2.07926ZM4.27941 7.07648L2.40353 8.00008L7.65902 10.5875C7.87342 10.6931 8.12675 10.6931 8.34115 10.5875L13.5966 8.00008L11.7207 7.07648L8.93015 8.45048C8.34435 8.73888 7.65582 8.73888 7.07002 8.45041L4.27941 7.07648ZM4.27942 10.4098L2.40353 11.3334L7.65902 13.9209C7.87342 14.0265 8.12675 14.0265 8.34115 13.9209L13.5966 11.3334L11.7207 10.4098L8.93015 11.7837C8.34435 12.0721 7.65582 12.0721 7.07002 11.7837L4.27942 10.4098Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1540_2507">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Related</span>
        </div>
      ) : null}

      {suggestedQuestions.map((suggestedQuestion, index) => (
        <div
          className="suggested-question"
          key={index}
          onClick={() => {
            onSuggestedQuestionClick(suggestedQuestion).catch(console.error);
          }}
        >
          <p>{suggestedQuestion}</p>
          <div className="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <mask id="mask0_1556_4262" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                <rect width="16" height="16" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1556_4262)">
                <path
                  d="M9.33342 12L8.40008 11.0333L10.7667 8.66667H2.66675V7.33333H10.7667L8.40008 4.96667L9.33342 4L13.3334 8L9.33342 12Z"
                  fill="#B1B1B1"
                />
              </g>
            </svg>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SuggestedQuestions;
